<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Phone Number") }}</div>
    <div class="details">{{ contentFormInfo.phone_number }}</div>
    <div class="titles">{{ __("Account") }}</div>
    <div class="details">
      {{ showAcName(contentFormInfo.ac_name) }}
    </div>
    <template v-if="!ctEnabled">
      <div class="titles" v-if="contentFormInfo.trunk_name">
        {{ __("Trunk") }}
      </div>
      <div class="details" v-if="contentFormInfo.trunk_name">
        {{ contentFormInfo.trunk_name }}
      </div>
    </template>
    <div class="titles">
      {{ __("Channel") }}
    </div>
    <div class="details">
      <el-tag style="padding: 0 3px 3px 3px;margin-left: 3px;">
        {{ contentFormInfo.channel }}
      </el-tag>
    </div>
    <div class="titles" v-if="contentFormInfo.tags">{{ __("Tags") }}</div>
    <div class="details" v-if="contentFormInfo.tags">
      <el-tag
        v-for="tag in contentFormInfo.tags.split(',')"
        :key="tag"
        style="padding: 0 3px 3px 3px;margin-left: 3px;"
      >
        {{ tag }}
      </el-tag>
    </div>
    <div
      style="margin-bottom: 15px;"
      v-if="contentFormInfo.channel === 'voice'"
    >
      <div class="titles">{{ __("Use Studio 6") }}</div>
      <div class="details">
        <el-switch
          :value="contentForm.use_studio_6"
          @change="switchVersion"
          :active-value="1"
          :inactive-value="0"
        ></el-switch>
      </div>
    </div>

    <el-button
      @click="handleEdit"
      class="editBtn"
      v-show="this.canWrite('phone_numbers')"
      >Edit</el-button
    >
    <el-button
      @click="handleDelete(contentFormInfo)"
      class="deleteBtn"
      v-show="this.canWrite('phone_numbers')"
      >Delete
    </el-button>
    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
    >
      <el-scrollbar :native="false" style="margin-top: 34px">
        <div style="max-height: calc(100vh - 34px);">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex" style="padding-top: 34px">
              <el-col :span="12" :offset="6">
                <page-header :title="__('Phone number')" :contentId="id" />
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Service Provider')" prop="sp_id">
                  <el-select
                    value-key="sp_id"
                    v-model="contentForm.sp_id"
                    placeholder="Select"
                    style="width: 100%"
                    @change="handleSpSelection"
                    filterable
                    default-first-option
                  >
                    <el-option
                      v-for="serviceProvider in this.serviceProviders"
                      :key="serviceProvider.sp_id"
                      :label="serviceProvider.sp_name"
                      :value="serviceProvider.sp_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item v-if="id === -1" :label="__('Phone numbers')">
                  <el-radio-group v-model="addMethod" class="display-inline">
                    <el-radio label="single">{{
                      __("Add single number")
                    }}</el-radio>
                    <el-radio label="range">{{
                      __("Add number range")
                    }}</el-radio>
                    <el-radio label="copy-paste">
                      {{ __("Copy/paste comma separated list") }}
                    </el-radio>
                    <el-radio label="upload">{{
                      __("Upload CSV file")
                    }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Phone Number')"
                  v-if="addMethod === 'single'"
                  prop="phone_number"
                  :required="addMethod === 'single'"
                >
                  <el-input v-model="contentForm.phone_number"></el-input>
                </el-form-item>
                <el-form-item
                  :label="__('Phone Number Range')"
                  v-else-if="addMethod === 'range'"
                  prop="phone_number_range"
                >
                  <div style="display: flex;">
                    <el-input
                      v-model="rangeFrom"
                      :required="addMethod === 'range'"
                    ></el-input>
                    <div style="width: 50px; padding: 0 5px">To</div>
                    <el-input
                      v-model="rangeTo"
                      :required="addMethod === 'range'"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item
                  v-else-if="addMethod === 'copy-paste'"
                  prop="phone_number"
                  :required="addMethod === 'copy-paste'"
                >
                  <el-input
                    type="textarea"
                    :rows="8"
                    :placeholder="__('Please enter comma separated list')"
                    v-model="contentForm.phone_number"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  v-else-if="addMethod === 'upload'"
                  prop="file_path"
                  :required="true"
                >
                  <csv-uploader
                    :fileUrl="contentForm.file_path"
                    :fileName="file_name"
                    folder="uploads"
                    fit="compact"
                    @on-success="handleSuccess"
                    @on-error="handleError"
                    @on-progress="isFileLoading = true"
                    @on-delete="handleFileDelete"
                  />
                </el-form-item>
                <el-form-item
                  :label="__('Phone Number')"
                  prop="phone_number"
                  v-else
                >
                  <el-input v-model="contentForm.phone_number"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6"> </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Channel')" prop="channel">
                  <el-select
                    v-model="contentForm.channel"
                    :disabled="id !== -1"
                    filterable
                    default-first-option
                  >
                    <el-option
                      v-for="item in phone_number_channels"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <br />
                  <span
                    v-if="contentForm.channel === 'sms_twilio'"
                    class="info-message"
                  >
                    {{
                      __(
                        "Please ensure correct region has been added in System settings for the phone number(s)."
                      )
                    }}
                  </span>
                </el-form-item>
              </el-col>
            </el-row>

            <div v-loading="isLoading">
              <el-row type="flex" v-if="!ctEnabled">
                <el-col :span="12" :offset="6">
                  <el-form-item
                    :label="__('SIP Trunk')"
                    v-if="contentForm.channel === 'voice'"
                    :required="contentForm.channel === 'voice'"
                    prop="trunk_id"
                  >
                    <el-select
                      v-model="contentForm.trunk_id"
                      style="width: 100%"
                      filterable
                      default-first-option
                    >
                      <el-option
                        v-for="trunk in this.selectedSpTrunks"
                        :key="trunk.trunk_id"
                        :label="trunk.trunk_name"
                        :value="trunk.trunk_id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row type="flex" v-if="contentForm.sp_id > 0">
                <el-col :span="12" :offset="6">
                  <el-form-item :label="__('Accounts')" prop="ac_id">
                    <el-select
                      value-key="ac_id"
                      v-model="contentForm.ac_id"
                      placeholder="Select"
                      style="width: 100%"
                      clearable
                      filterable
                      default-first-option
                    >
                      <el-option
                        v-for="account in this.selectedSpAccounts"
                        :key="account.ac_id"
                        :label="account.ac_name"
                        :value="account.ac_id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

            <!--            Add channel specific fields which are added to phone_number_config table-->
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="this.taskLookupLabel"
                  v-show="this.showTaskLookupId"
                  prop="task_lookup_id"
                >
                  <el-input v-model="contentForm.task_lookup_id"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="this.channelKey1Label"
                  v-show="this.showChannelKey1"
                >
                  <el-input v-model="channel_key_1"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="this.channelKey2Label"
                  v-show="this.showChannelKey2"
                >
                  <el-input v-model="channel_key_2"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <!--            End of channel specific details-->

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item class="form_label_top" :label="__('Tags')">
                  <el-tag
                    :key="tag"
                    v-for="tag in getTagsArray(contentForm.tags)"
                    closable
                    :disable-transitions="false"
                    @close="handleDeleteTag(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="inputVisible"
                    v-model="inputValue"
                    ref="saveTagInput"
                    @keyup.enter.native="handleInputConfirm"
                    @blur="handleInputConfirm"
                  >
                  </el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    @click="showInput"
                    size="small"
                    id="add_tag_btn"
                  >
                    + {{ __("New Tag") }}</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px"
          >
            <save-button
              type="primary"
              @click="submitForm"
              class="submitBtn"
              v-show="this.canWrite('phone_numbers')"
              :primary-key="this.id"
              variant="CreateUpdate"
            />
            <el-button @click="handleCancelBtn" class="cancelBtn">{{
              __("Cancel")
            }}</el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { mapActions, mapState, mapGetters } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import _ from "lodash";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import CsvUploader from "@/components/uploaders/types/CsvUploader";
import { removeFiles } from "@/api/services";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton,
    CsvUploader
  },
  data() {
    var validateTaskLookupId = (rule, value, callback) => {
      if (this.contentForm.channel === "whatsapp_sinch" && value === "") {
        callback(new Error(__("Bot ID is required for Sinch numbers")));
      } else {
        callback();
      }
    };
    const validatePhoneNumber = (rule, value, callback) => {
      if (this.addMethod === "single") {
        if (!this.contentForm.phone_number.length) {
          callback(__("phone number is required"));
        } else {
          if (!/^\+?\d*$/.test(this.contentForm.phone_number)) {
            callback(__("phone number is invalid"));
          } else {
            callback();
          }
        }
      } else if (this.addMethod === "copy-paste") {
        if (!this.contentForm.phone_number.length) {
          callback(__("comma separated numbers are required"));
        } else {
          if (!/^\+?\d+(?:,\+?\d+)*$/.test(this.contentForm.phone_number)) {
            callback(__("comma separated numbers are invalid"));
          } else {
            callback();
          }
        }
      } else {
        callback();
      }
    };
    const validateRange = (rule, value, callback) => {
      if (this.addMethod === "range") {
        if (!this.rangeFrom.length) {
          callback(__("range starting phone number is required"));
        } else if (!this.rangeTo.length) {
          callback(__("range ending phone number is required"));
        } else if (!/^\+?\d*$/.test(this.rangeFrom)) {
          callback(__("range starting phone number is invalid"));
        } else if (!/^\+?\d*$/.test(this.rangeTo)) {
          callback(__("range ending phone number is invalid"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    const validateTrunk = (rule, value, callback) => {
      if (!value && !this.ctEnabled) {
        callback(__("Trunk is required"));
      } else {
        callback();
      }
    };
    const validateCsvUpload = (rule, value, callback) => {
      if (this.addMethod === "upload") {
        if (_.isEmpty(value)) {
          callback(__("a csv file upload is required"));
        } else {
          callback();
        }
      }
    };

    return {
      rules: {
        file_path: [
          {
            trigger: "blur",
            validator: validateCsvUpload
          }
        ],
        sp_id: [
          {
            required: true,
            trigger: "blur",
            message: __("Service Provider is required")
          }
        ],
        phone_number: [
          {
            required: true,
            trigger: "blur",
            validator: validatePhoneNumber
          }
        ],
        phone_number_range: [
          {
            required: true,
            trigger: "blur",
            validator: validateRange
          }
        ],
        channel: [
          {
            required: true,
            trigger: "blur",
            message: __("Phone number channel is required")
          }
        ],
        trunk_id: [
          {
            trigger: "blur",
            validator: validateTrunk
          }
        ],
        task_lookup_id: [
          {
            required: true,
            validator: validateTaskLookupId,
            trigger: "blur"
          }
        ]
      },
      additionalValidateRoute: "phone-numbers",
      addMethod: "single",
      rangeFrom: "",
      rangeTo: "",
      file_name: "",
      uploadedFilePaths: [],
      isFileLoading: false, // to disable submit button while the upload is in progress
      isSubmitting: false,
      inputVisible: false,
      inputValue: "",
      selectedSpID: "",
      channel_key_1: "",
      channel_key_2: "",
      phone_number_channels: [
        {
          value: "voice",
          label: "voice"
        },
        {
          value: "sms_twilio",
          label: "sms_twilio"
        },
        {
          value: "sms_nexmo",
          label: "sms_nexmo"
        },
        {
          value: "whatsapp_twilio",
          label: "whatsapp_twilio"
        },
        {
          value: "whatsapp_sinch",
          label: "whatsapp_sinch"
        },
        {
          value: "whatsapp_360dialog",
          label: "whatsapp_360dialog"
        },
        {
          value: "sms_wavecell",
          label: "sms_wavecell"
        }
      ]
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),

    ...mapState("serviceproviders", {
      serviceProviders: state => state.serviceProviders,
      selectedSpAccounts: state => state.selectedSpAccounts,
      selectedSpTrunks: state => state.selectedSpTrunks,
      isLoading: state => state.loading
    }),

    ...mapGetters("serviceproviders", {
      accounts: "accounts",
      trunks: "trunks"
    }),
    ...mapGetters("app", {
      ctEnabled: "ctEnabled"
    }),

    showAcName() {
      return ac_name => {
        return ac_name ? ac_name : __("Not Mapped");
      };
    },

    showTaskLookupId() {
      return (
        this.addMethod === "single" &&
        this.contentForm.channel === "whatsapp_sinch"
      );
    },

    taskLookupLabel() {
      if (this.contentForm.channel === "whatsapp_sinch") {
        return "Bot ID";
      } else return "";
    },

    showChannelKey1() {
      return (
        (this.id !== -1 || this.addMethod === "single") &&
        (this.contentForm.channel === "whatsapp_twilio" ||
          this.contentForm.channel === "whatsapp_360dialog" ||
          this.contentForm.channel === "whatsapp_sinch" ||
          this.contentForm.channel === "sms_twilio" ||
          this.contentForm.channel === "sms_nexmo" ||
          this.contentForm.channel === "sms_wavecell")
      );
    },

    showChannelKey2() {
      return (
        (this.id !== -1 || this.addMethod === "single") &&
        (this.contentForm.channel === "whatsapp_twilio" ||
          this.contentForm.channel === "sms_twilio" ||
          this.contentForm.channel === "sms_nexmo")
      );
    },

    channelKey1Label() {
      if (
        this.contentForm.channel === "whatsapp_twilio" ||
        this.contentForm.channel === "sms_twilio"
      ) {
        return "Account SID";
      } else if (this.contentForm.channel === "whatsapp_360dialog") {
        return "API Key";
      } else if (this.contentForm.channel === "whatsapp_sinch") {
        return "Token";
      } else if (this.contentForm.channel === "sms_nexmo") {
        return "API Key";
      } else if (this.contentForm.channel === "sms_wavecell") {
        return "API Key";
      } else return "";
    },

    channelKey2Label() {
      if (
        this.contentForm.channel === "whatsapp_twilio" ||
        this.contentForm.channel === "sms_twilio"
      ) {
        return "Auth Token";
      } else if (this.contentForm.channel === "sms_nexmo") {
        return "API Secret";
      } else return "";
    }
  },

  methods: {
    ...mapActions("phonenumbers", {
      createPhoneNumber: "createPhoneNumber",
      updatePhoneNumber: "updatePhoneNumber",
      deleteContentMethod: "deletePhoneNumber",
      undoDeleteContent: "undoDeletePhoneNumber",
      switchStudioVersion: "switchStudioVersion"
    }),
    ...mapActions("serviceproviders", {
      fetchAccountsForSelectedSP: "fetchAccountsForSelectedSP",
      fetchTrunksForSelectedSP: "fetchTrunksForSelectedSP"
    }),
    getTagsArray(tags) {
      return tags ? tags.split(",") : [];
    },
    handleCancelBtn() {
      this.addMethod = "single";
      this.selectedSpID = "";
      this.resetNumRangeContent();
      this.resetFileFormContent();
      this.handleCancel();
    },
    handleDeleteTag(tag) {
      let tagsArray = _.split(this.contentForm.tags, ",");
      const tagIndex = _.findIndex(tagsArray, tagA => {
        return tagA === tag;
      });
      tagsArray.splice(tagIndex, 1);
      this.contentForm.tags = tagsArray.join(",");
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        if (this.contentForm.tags) {
          this.contentForm.tags += "," + inputValue;
        } else {
          this.contentForm.tags += inputValue;
        }
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    handleError(err) {
      console.log(err);
      this.isFileLoading = false;
    },
    handleSuccess(url) {
      this.isFileLoading = false;
      this.uploadedFilePaths.push(url.path);
      this.$set(this.contentForm, "file_path", url.path);
      this.file_name = url.uploaded_file_name;
    },
    handleFileDelete() {
      this.resetFileFormContent();
    },
    resetFileFormContent() {
      this.$set(this.contentForm, "file_path", "");
      this.file_name = "";
    },
    resetNumRangeContent() {
      this.rangeFrom = "";
      this.rangeTo = "";
    },
    removeUploadedFiles() {
      removeFiles(this.uploadedFilePaths);
    },
    async handleSpSelection(sp) {
      this.selectedSpID = sp;
      if (sp === this.contentFormInfo.sp_id) {
        this.contentForm.trunk_id = this.contentFormInfo.trunk_id;
        this.contentForm.ac_id = this.contentFormInfo.ac_id;
        this.contentForm.channel = this.contentFormInfo.channel;
      } else {
        if (this.contentForm.trunk_id) {
          this.contentForm.trunk_id = "";
        }
        if (this.contentForm.ac_id) {
          this.contentForm.ac_id = "";
        }
      }
      await this.fetchAccountsForSelectedSP(sp);
      await this.fetchTrunksForSelectedSP(sp);
    },
    getChannelAuthDetailJson() {
      if (this.channel_key_1 === "") return "";
      if (
        this.contentForm.channel === "sms_twilio" ||
        this.contentForm.channel === "whatsapp_twilio"
      ) {
        return JSON.stringify({
          account_sid: this.channel_key_1,
          auth_token: this.channel_key_2
        });
      } else if (this.contentForm.channel === "sms_nexmo") {
        return JSON.stringify({
          api_key: this.channel_key_1,
          api_secret: this.channel_key_2
        });
      } else if (this.contentForm.channel === "sms_wavecell") {
        return JSON.stringify({
          api_key: this.channel_key_1
        });
      } else if (this.contentForm.channel === "whatsapp_sinch") {
        return JSON.stringify({
          token: this.channel_key_1
        });
      } else if (this.contentForm.channel === "whatsapp_360dialog") {
        return JSON.stringify({
          api_key: this.channel_key_1
        });
      }
      this.channel_key_1 = "";
      this.channel_key_2 = "";
    },
    extractChannelAuthDetailJson() {
      try {
        let obj = JSON.parse(this.contentForm.auth_detail);
        if (
          this.contentForm.channel === "sms_twilio" ||
          this.contentForm.channel === "whatsapp_twilio"
        ) {
          this.channel_key_1 = obj.account_sid;
          this.channel_key_2 = obj.auth_token;
        } else if (this.contentForm.channel === "sms_nexmo") {
          this.channel_key_1 = obj.api_key;
          this.channel_key_2 = obj.api_secret;
        } else if (this.contentForm.channel === "sms_wavecell") {
          this.channel_key_1 = obj.api_key;
        } else if (this.contentForm.channel === "whatsapp_sinch") {
          this.channel_key_1 = obj.token;
        } else if (this.contentForm.channel === "whatsapp_360dialog") {
          this.channel_key_1 = obj.api_key;
        }
      } catch (e) {
        this.channel_key_1 = "";
        this.channel_key_2 = "";
      }
    },
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createPhoneNumber : this.updatePhoneNumber;

          if (this.addMethod === "range") {
            this.contentForm.phone_number = this.rangeFrom + "-" + this.rangeTo;
          } else if (this.addMethod === "upload") {
            this.contentForm.phone_number = this.contentForm.file_path;
          }
          this.contentForm.auth_detail = this.getChannelAuthDetailJson();
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;
              if (this.addMethod !== "single") {
                this.addMethod = "single";
                this.resetNumRangeContent();
                this.resetFileFormContent();
              }
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: data.message
                  })
                : this.$message({
                    type: "success",
                    message: __("Phone Number updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.response.data.message
              });
            });
        }
      });
    },
    beforeDestroy() {
      this.removeUploadedFiles();
    },

    switchVersion() {
      let switchToVersion = this.contentForm.use_studio_6 ? "7" : "6";
      this.$confirm(
        __("Switch this number to studio :switch_to_version", {
          switch_to_version: switchToVersion
        }),
        "Switch Studio Version",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          let useStudio6 = !this.contentForm.use_studio_6;
          this.switchStudioVersion({
            phoneNumber: this.contentForm,
            useStudio6
          })
            .then(data => {
              EventBus.$emit("list-changed", data.data);
              this.$message({
                type: "success",
                message: __("Version switch completed")
              });
            })
            .catch(error => {
              console.log(error);
              this.$message({
                type: "error",
                message: __("Switch failed")
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: __("Switch canceled")
          });
        });
    }
  },
  watch: {
    formAction: {
      immediate: true,
      deep: true,
      handler: function(val) {
        if (val === "edit") {
          this.extractChannelAuthDetailJson();
          this.fetchAccountsForSelectedSP(this.contentFormInfo.sp_id);
          this.fetchTrunksForSelectedSP(this.contentFormInfo.sp_id);
        } else {
          this.channel_key_1 = "";
          this.channel_key_2 = "";
        }
      }
    },
    addMethod: {
      deep: true,
      immediate: true,
      handler: function(method) {
        // Reset the uploaded file url field if method is not upload
        if (method !== "upload") {
          this.resetFileFormContent();
        }
        // clear validation message
        if (this.$refs.contentForm) {
          this.$refs.contentForm.clearValidate();
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

.submitBtn.is-disabled {
  color: white;
  //background-color: mix(white, $content-theme-color, 50%);
  border-color: $content-theme-outline-color !important;
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}

.info-message {
  display: block;
  padding-top: 5px;
  text-align: left;
  word-wrap: normal;
  word-break: keep-all;
  line-height: 24px;
}
</style>
